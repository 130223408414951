import React, { useRef, useState, useEffect, useMemo } from 'react'
import FullPageIntro from './FullPageIntro'
import { motion, useViewportScroll } from 'framer-motion'

import './PetzebelIntro.scss'

const PetzebelIntro = () => {
  const { scrollY } = useViewportScroll()
  const [currentScrollY, setCurrentScrollY] = useState(0)
  // const [bottomVisible, setBottomVisible] = useState(false)
  const [scrollPercentage, setScrollPercentage] = useState(0)
  const [scrollHeight, setScrollHeight] = useState(0)
  // const [widthDif, setWidthDif] = useState(1)
  // const ref = useRef()
  const ref = useRef()

  
  useEffect(() => {
    if (!ref.current) return

    return scrollY.onChange((v) => {
      setCurrentScrollY(v)
    })
  }, [ref, scrollY])

  const introVisible = useMemo(() => {
    if (!ref || !ref.current || currentScrollY <= 10) return
    const boundingRect = ref.current.getBoundingClientRect()
    const elementScrollStart = boundingRect.top / window.innerHeight
    setScrollHeight(boundingRect.height);
    if (elementScrollStart < 0.6 && elementScrollStart > -0.9) {
      setScrollPercentage((elementScrollStart - 0.6) / -1.2)
    }
    return elementScrollStart < 0.6 && boundingRect.bottom > 0
  }, [currentScrollY])


  // const scrollIntroPercentage = useMemo(() => {
  //     if (!ref.current || currentScrollY <= 10) return;
  //     const boundingRect = ref.current.getBoundingClientRect();
  //     const imgRect = imgRef.current.getBoundingClientRect();
  //     const widthDif = imgRect.width - boundingRect.width;
  //     const scrollPercentage = boundingRect.y / window.innerHeight;
  //     setWidthDif(widthDif);
  //     setScrollValue(((scrollPercentage + 1) / 2) * widthDif);
  //     if (boundingRect.y + boundingRect.height < window.innerHeight) {
  //         setBottomVisible(true);
  //     } else {
  //         setBottomVisible(false);
  //     }
  //     return scrollPercentage;
  // }, [currentScrollY]);

  // const scrollValue = if (elementScrollStart < .5 && elementScrollStart > -0.6) {
  //
  // }

  // let style = {
  //     position: 'relative'
  // };

  // if (widthDif <= 0) {
  //     style.width = '100%';
  //     style.objectFit = 'cover';
  // }

  // let containerStyle = {

  // }

  // if (scrollIntroPercentage <= 1 && scrollIntroPercentage >= -1) {
  //     style.transform = `translate(-${scrollValue}px, 0)`;
  // }
  // if (bottomVisible) {
  //     style.position = 'relative';
  //     style.transform = `translate(-${scrollValue}px, 20vh)`;
  // } else if (scrollIntroPercentage < 0.9) {
  //     style.position = 'fixed';
  // }

  return (
    <FullPageIntro title="petzebel" className="petzebel-intro">
      <img
        className="petzebel-intro__img"
        src="/img/petzebel_intro_slide.jpg"
        alt="petzebel"
      />
      <div
        ref={ref} className="petzebel-intro__title">
        <div className="petzebel-intro__title1">Petzebel</div>
        <div className="petzebel-intro__title2">Petzebel</div>
        <div className="petzebel-intro__title3">Petzebel</div>
        <div className="petzebel-intro__title4">Petzebel</div>
      </div>
      <motion.div
        initial={{y: 0}}
        animate={{y: introVisible ? scrollPercentage * (scrollHeight / 4) : 0 }}
        className="petzebel-intro__subtitle">
        <motion.span
          initial={{x: 0, opacity: 0}}
          animate={{x: introVisible ? -20 : 0, opacity: introVisible ? 1 : 0 }}
          transition={{ delay: 0 }}
          className="petzebel-intro__live">Live</motion.span>
        <motion.span
          initial={{x: 0, opacity: 0}}
          animate={{x: introVisible ? -20 : 0, opacity: introVisible ? 1 : 0 }}
          transition={{ delay: 0.4 }}
          className="petzebel-intro__laugh">Laugh</motion.span>
        <motion.span
          initial={{x: 0, opacity: 0}}
          animate={{x: introVisible ? -20 : 0, opacity: introVisible ? 1 : 0 }}
          transition={{ delay: 0.8 }}
          className="petzebel-intro__leash">Leash</motion.span>
      </motion.div>
    </FullPageIntro>
  )
}

export default PetzebelIntro
