import React, { useRef, useState, useEffect, useMemo } from 'react'
import FullPageIntro from './FullPageIntro'
import { useViewportScroll } from 'framer-motion'
import SizeHook from '../../utils/SizeHook.js'

import './BioBreakIntro.scss'

const BioBreakIntro = () => {
  const ref = useRef()
  const imgRef = useRef()
  const size = SizeHook(imgRef)
  const { scrollY } = useViewportScroll()
  const [currentScrollY, setCurrentScrollY] = useState(0)

  useEffect(() => {
    if (!ref.current) return

    return scrollY.onChange((v) => {
      setCurrentScrollY(v)
    })
  }, [ref, scrollY])

  const bottomVisible = useMemo(() => {
    if (!ref.current || currentScrollY <= 10) return
    const boundingRect = ref.current.getBoundingClientRect()

    return boundingRect.bottom <= size.height
  }, [currentScrollY])

  const scrollIntroPercentage = useMemo(() => {
    if (!ref.current || currentScrollY <= 10) return
    const boundingRect = ref.current.getBoundingClientRect()
    const scrollPercentage =
      boundingRect.y < 0 ? -boundingRect.y / boundingRect.height : 0
    return scrollPercentage
  }, [currentScrollY])

  let backgroundStyle = {
    position: 'fixed',
    top: 0,
    transform: 'scale(1.5)',
  }

  let foregroundStyle1 = { opacity: 0 }
  let foregroundStyle2 = { opacity: 0 }
  let foregroundStyle3 = { opacity: 0 }
  let foregroundStyle4 = { opacity: 0 }
  let headlineStyle = { opacity: 0, transform: 'scale(1.5)' }

  if (!bottomVisible && scrollIntroPercentage < 0.5) {
    backgroundStyle.transform = `scale(${1.5 - scrollIntroPercentage})`
  }

  if (scrollIntroPercentage > 0.01 && scrollIntroPercentage <= 0.18) {
    foregroundStyle1.opacity = 1
  } else if (scrollIntroPercentage > 0.18 && scrollIntroPercentage <= 0.26) {
    foregroundStyle2.opacity = 1
  } else if (scrollIntroPercentage > 0.26 && scrollIntroPercentage <= 0.32) {
    foregroundStyle3.opacity = 1
  } else if (scrollIntroPercentage > 0.32) {
    foregroundStyle4.opacity = 1
  }
  if (scrollIntroPercentage > 0.38) {
    headlineStyle.opacity = 1
    headlineStyle.transform = 'scale(1)'
  }

  if (bottomVisible) {
    backgroundStyle = {
      position: 'absolute',
      x: 0,
      top: size.height,
    }
  }

  return (
    <FullPageIntro title="biobreak" className="bio-break-intro">
      <div ref={ref} className="bio-break-intro__container">
        <div
          ref={imgRef}
          className="bio-break-intro__images"
          style={backgroundStyle}
        >
          <img
            className="bio-break-intro__background"
            src="/img/biobreak_call.jpg"
            alt="thebiobreak"
          />
          <img
            style={foregroundStyle1}
            className="bio-break-intro__foreground-1"
            src="/img/biobreak_call_1.png"
            alt="foreground1"
          />
          <img
            style={foregroundStyle2}
            className="bio-break-intro__foreground-2"
            src="/img/biobreak_call_2.png"
            alt="foreground2"
          />
          <img
            style={foregroundStyle3}
            className="bio-break-intro__foreground-3"
            src="/img/biobreak_call_3.png"
            alt="foreground3"
          />
          <img
            style={foregroundStyle4}
            className="bio-break-intro__foreground-4"
            src="/img/biobreak_call_4.png"
            alt="foreground4"
          />
          <div style={headlineStyle} className="bio-break-intro__headline">
            <span className="bio-break-intro__title">TheBioBreak</span>
            <span className="bio-break-intro__subtitle">
              news between zooms
            </span>
          </div>
        </div>
      </div>
    </FullPageIntro>
  )
}

export default BioBreakIntro
