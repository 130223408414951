import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import React, { Suspense, useRef, useEffect, useState, useMemo } from 'react'
import { Canvas, useFrame, useLoader } from 'react-three-fiber'
import { useViewportScroll } from 'framer-motion'

const KeyboardPrimitive = ({ keyboardVisible, scrollPercentage }) => {
  const mesh = useRef()

  let devBranchCharacters = []

  useFrame(() => {
    if (keyboardVisible) {
      mesh.current.position.y = 6 * scrollPercentage - 3
    }
  })

  useEffect(() => {
    if (mesh) {
      mesh.current.children.forEach((child) => {
        if (child.name === 'D') {
          devBranchCharacters[0] = child
        }
        if (child.name === 'E') {
          devBranchCharacters[1] = child
        }
        if (child.name === 'V') {
          devBranchCharacters[2] = child
        }
        if (child.name === 'B') {
          devBranchCharacters[3] = child
        }
        if (child.name === 'R') {
          devBranchCharacters[4] = child
        }
        if (child.name === 'A') {
          devBranchCharacters[5] = child
        }
        if (child.name === 'N') {
          devBranchCharacters[6] = child
        }
        if (child.name === 'C') {
          devBranchCharacters[7] = child
        }
        if (child.name === 'H') {
          devBranchCharacters[8] = child
        }
      })
      devBranchCharacters.forEach((character, index) => {
        let xVal = -0.1 + index * 0.02
        if (index === 1) {
          //xVal += 0.008;
        } else if (index === 2) {
          //xVal -= 0.005;
        } else if (index === 5) {
          //xVal += 0.087;
        } else if (index === 4) {
          //xVal += 0.025;
        } else if (index === 7) {
          //xVal += 0.062;
        } else if (index === 8) {
          //xVal += 0.01;
        }
        character.position.x = xVal
        character.position.y = 0.05
        character.position.z = -0.05
      })
    }
  })

  const gltf = useLoader(GLTFLoader, '/img/keyboard.gltf')

  let scale = [19, 19, 19]
  let position = [0, -2, 4]

  if (window.innerWidth < 1000 && window.innerWidth > 600) {
    scale = [19, 19, 19]
    position = [0, -2, 0]
  } else if (window.innerWidth <= 599) {
    scale = [12, 12, 12]
    position = [0, -2, -1]
  } else if (window.innerWidth >= 1000 && window.innerWidth <= 1300) {
    //scale = [19,19,19];
    position = [0, -2, 0]
  }

  return (
    <primitive
      ref={mesh}
      object={gltf.scene}
      position={position}
      scale={scale}
      rotation={[0.5 * Math.PI, 0, 0]}
    />
  )
}

const Keyboard = () => {
  const { scrollY } = useViewportScroll()
  const [currentScrollY, setCurrentScrollY] = useState(0)
  const [scrollPercentage, setScrollPercentage] = useState(0)
  const ref = useRef()
  useEffect(() => {
    if (!ref.current) return

    return scrollY.onChange((v) => {
      setCurrentScrollY(v)
    })
  }, [ref, scrollY])

  const keyboardVisible = useMemo(() => {
    if (!ref || !ref.current || currentScrollY <= 10) return
    const boundingRect = ref.current.getBoundingClientRect()
    const elementScrollStart = boundingRect.top / window.innerHeight
    if (elementScrollStart < 0.8 && elementScrollStart > -0.4) {
      setScrollPercentage((elementScrollStart - 0.6) / -1.2)
    }
    return elementScrollStart < 0.8 && boundingRect.bottom > 0
  }, [currentScrollY])

  // 1920 / 1080 = 1.9
  // 1080 / 1920 = 0.5

  return (
    <div ref={ref} style={{ width: '100%', height: '100%' }}>
      {/* I need to support multiple viewports for this view / I need aspect ratio */}
      <Canvas
        style={{ zIndex: 2 }}
        camera={{ fov: 60, position: [0, 0, 10] }}
        resize={{ scroll: false }}
      >
        <spotLight
          position={[0, 0, 10]}
          intensity={1.4}
          castShadow={true}
          color="#FFFFFF"
        />

        {/* <pointLight position={[15, 0, 10]} intensity={1} color="#168EFF" /> */}
        {/* <pointLight position={[0, 0, 10]} intensity={1} color="#FF42A9" /> */}
        {/* <pointLight position={[-15, 0, 10]} intensity={20} color="#E800F0" /> */}
        <Suspense fallback={null}>
          <KeyboardPrimitive
            keyboardVisible={keyboardVisible}
            scrollPercentage={scrollPercentage}
          />
        </Suspense>
      </Canvas>
    </div>
  )
}

export default Keyboard
