import React from 'react'
import PropTypes from 'prop-types'

import './FullPageIntro.scss'

const FullPageIntro = ({ staticImage, className, children }) => {
  let style = {}
  if (staticImage) {
    style.backgroundImage = `url("${staticImage}")`
    style.backgroundAttachment = 'fixed'
  } else {
    style.backgroundColor = '#fff'
  }
  return (
    <div style={style} className={`full-page-intro ${className}`}>
      {children}
    </div>
  )
}

FullPageIntro.propTypes = {
  title: PropTypes.string,
  staticImage: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
}

export default FullPageIntro
