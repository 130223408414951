import React from 'react'
import FullPageIntro from './FullPageIntro'
import Keyboard from './Keyboard'

import './DevBranchIntro.scss'

const DevBranchIntro = () => {
  return (
    <FullPageIntro title="devbranch" className="dev-branch-intro">
      <img
        alt="desk"
        className="dev-branch-intro__desk"
        src="/img/thedevbranch_intro.jpg"
      />
      {/* <motion.img
                className="dev-branch-intro__keyboard"
                alt="keyboard"
                src="/img/thedevbranch_intro_kb.png" />
            
            <motion.img
                className="dev-branch-intro__whiteboard"
                alt="whiteboard"
                src="/img/fizzbuzz_whiteboard.jpg" />
            <motion.img
                className="dev-branch-intro__user"
                alt="user"
                src="/img/whiteboard_user.png" /> */}
      <Keyboard />
    </FullPageIntro>
  )
}

export default DevBranchIntro
